export const commonTrans = {
  confirm: "Potwierdzam",
  subscription_required: "Wymagana aktywna subskrypcja",
  refresh: "Odśwież",
  change: "Zmień",
  gender: "Płeć",
  references: "Referencje własne",
  first_name: "Imię",
  postal_code: "Kod pocztowy",
  country: "Kraj",
  city: "Miasto",
  street: "Ulica",
  house_number: "Numer budynku",
  flat_number: "Numer lokalu",
  phone_number: "Numer telefonu",
  ue: "Unia Europejska",
  ppv_service_name: "Szybki Skan AML",
  login: "Zaloguj",
  logout: "Wyloguj",
  cancel: "Anuluj",
  delete: "Usuń",
  create: "Utwórz",
  import: "Importuj",
  add: "Dodaj",
  added: "Dodano",
  next: "Dalej",
  choose: "Wybierz",
  add_data: "Dodaj dane",
  data: "Dane",
  edit: "Edytuj",
  edition_left: "Liczba pozostałych edycji: ",
  more: "Więcej",
  save: "Zapisz",
  save_confirmation: "Czy na pewno chcesz zapisać zmiany?",
  set: "Ustaw",
  close: "Zamknij",
  continue: "Kontynuuj",
  details: "Szczegóły",
  return: "Powrót",
  create_another_one: "Utwórz kolejnego",
  male_create_new: "Utwórz nowy",
  female_create_new: "Utwórz nowe",
  create_another: "Utwórz kolejne",
  create_another_2: "Utwórz kolejny",
  search: "Wyszukaj",
  download: "Pobierz",
  download_pdf: "Pobierz (PDF)",
  download_invoice_pdf: "Pobierz fakturę (PDF)",
  approve: "Zatwierdź",
  try_again: "Spróbuj ponownie",
  industries: "Branże",
  warning: "Ostrzeżenie",
  yes: "Tak",
  no: "Nie",
  no_options: "Brak opcji",
  not_defined: "brak informacji",
  not_selected: "nie wybrano",
  accept: "Akceptuj",
  reject: "Odrzuć",
  month: "miesiąc",
  months: "miesiące",
  months_3: "miesięcy",
  quarter: "kwartał",
  year: "rok",
  code: "Kod",
  description: "Opis",
  date: "Data",
  attention: "Uwaga",
  summary: "Podsumowanie",
  bill: "Rachunek",
  confirmation: "Potwierdzenie",
  advanced_search: "Wyszukiwanie zaawansowane",
  last_name: "Nazwisko",
  company_name: "Nazwa firmy",
  risk_assessment: "Ocena ryzyka",
  rule_settings: "Ustawienia reguł",
  events: "Zdarzenia",
  attachments: "Załączniki",
  documents: "Dokumenty",
  status_change: "Zmień status",
  accommodation_address: "Adres zamieszkania",
  forward_address: "Adres korespondencyjny",
  business_address: "Adres prowadzenia działalności",
  add_forward_address: "Dodaj adres korespondencyjny",
  remove_forward_address: "Usuń adres korespondencyjny",
  contact_data: "Dane kontaktowe",
  company_contact: "Dane kontaktowe działalności",
  company_contact_add: "Dodaj dane kontaktowe działalności",
  company_contact_remove: "Usuń dane kontaktowe działalności",
  email_address: "Adres e-mail",
  phone_country: "Kod kraju",
  document_templates: "Szablon dokumentu",
  rule: "Reguła",
  page_not_found: "Strona nie znaleziona!",
  path_correct: "Upewnij się czy podana ścieżka jest prawidłowa albo",
  homepage: "stronę startową",
  go_to: "przejdź do",
  page_found: "Nie znaleziono strony",
  aml_check: "Sprawdzenie AML",
  main_panel: "Panel główny",
  order: "Zamówienie",
  go_to_summary: "Przejdź do podsumowania",
  go_back_to_summary: "Wróć do podsumowania",
  products: "Produkty",
  cart: "Koszyk",

  document_types: {
    id_card: "Dowód osobisty",
    electronic_id_card: "mDowód",
    passport: "Paszport",
    residency_card: "Karta pobytu",
  },

  document_without_expiration_date: {
    true: "Tak",
    false: "Nie",
  },

  data_validation: {
    too_early: "Wybierz datę nie wcześniejszą niż {{$data}}",
    too_late: "Wybierz datę nie późniejszą niż {{$data}}",
    invalid: "Data nie jest prawidłowa",
    past_date: "Wybrano datę z przeszłości, sprawdź poprawność",
    future_date: "Wybrano datę z przyszłości, sprawdź poprawność",
  },

  report: {
    generation_info: "Rozpoczęto proces tworzenia raportu, będzie on możliwy do pobrania z zakładki dokumenty",
    generation_info_tools:"Rozpoczęto proces tworzenia raportu, będzie on możliwy do pobrania z tabeli poniżej",
    download_in_progress: "Trwa pobieranie",
    downloaded: "Pobrano",
    prepare_report: "Przygotuj raport",
    create_giff_form: "Utwórz formularz zgłoszeniowy do GIIF",
  },
  table: {
    code: "Kod",
    description: "Opis",
    name: "Nazwa",
    party: "Podmiot",
    date: "Data",
    status: "Status",
    payment_date: "Data płatności",
    booked_at:"Data zlecenia transakcji",
    created_at: "Data utworzenia",
    amount: "Kwota",
    invoice: "Faktura",
    invoice_number: "Numer faktury",
    transaction_entity: "Strona transakcji",
    title: "Tytuł",
    type: "Rodzaj",
  },
  industries_submenu: {
    museum: "AML dla Antykwariatów, Galerii Sztuki i Domów Aukcyjnych",
    bureau: "System AML dla biur rachunkowych",
    stock: "AML dla kantorów i giełd walut wirtualnych",
    payment: "System AML dla małych instytucji płatniczych i biur usług płatniczych",
  },
  systemAML: "System AML",
  required: "Wymagane",
  party: "Podmiot",
  parties: "Podmioty",
  transaction: "Transakcja",
  transactions: "Transakcje",
  beneficiaries: "Beneficjenci",
  board_members: "Reprezentanci",
  verification: "Weryfikacja",
  cdd_list: "ŚBF - Lista",
  cdd: "ŚBF",
  customer_due_diligence: "Środki bezpieczeństwa finansowego",
  verifications: "Weryfikacje",
  applicant: "Aplikant",
  pagination: {
    display: "Wyświetlaj",
    from: "z",
  },
  entity_type: {
    individual: "Osoba",
    individual_entity: "Osoba",
    beneficiary: "Osoba (beneficjent)",
    board_member: "Osoba (reprezentant)",
    sole_proprietorship: "Jednoosobowa działalność gospodarcza",
    legal_entity: "Osoba prawna",
  },
  sanctioning_institutions: {
    ue: "UE",
    un: "ONZ",
    mswia: "MSWiA",
  },
  sanction_list_name: {
    sanctions_giif: "Lista sankcyjna GIIF",
    sanctions_mswia: "Lista sankcyjna MSWiA",
    eu_financial_sanctions: "Lista sankcyjna EU (EU Financial Sanctions)",
    sanctions_un: "Lista sankcyjna ONZ (United Nations Security Council)",
    sanctions_uk: "Lista sankcyjna UK (HM Treasury)",
    sanctions_us_non_sdn: "Lista sankcyjna nie-SDN USA (OFAC non Specially Designated Nationals)",
    sanctions_us_sdn: "Lista sankcyjna SDN USA (OFAC Specially Designated Nationals)",
  },
  select_party: {
    redirect: "Podmiot został pozyskany w wyniku przekierowania.",
    no_party: "Żaden podmiot nie został jeszcze utworzony w systemie AML.",
    label: "Lista podmiotów",
  },
  party_types: {
    individual: "Osoba fizyczna",
    sole_proprietorship: "Jednoosobowa działalność gospodarcza",
    company: "Osoba prawna",
    unknown: "Nie zdefiniowano",
    representative: "Reprezentant",
  },
  party_status: {
    active: "Aktywny",
    occasional: "Okazjonalny",
    in_acceptance: "W akceptacji",
    inactive: "Nieaktywny",
    draft: "Wersja robocza",
    kyc_in_progress: "KYC w toku",
    rejected: "Odrzucono",
  },
  select_transaction: {
    redirect: "Transakcja została pozyskana w wyniku przekierowania.",
    no_transaction: "Żadna transakcja nie została jeszcze utworzona w systemie AML.",
    label: "Lista transakcji",
  },
  transaction_types: {
    buyer: "Kupno",
    buyer_crypto: "Skup waluty wirtualnej",
    buyer_fiat: "Kupno waluty fiducjarnej",
    seller: "Sprzedaż",
    seller_crypto: "Sprzedaż waluty wirtualnej",
    seller_fiat: "Sprzedaż waluty fiducjarnej",
    exchange_fiat: "Wymiana waluty fiducjarnej",
    transfer: "Płatnicza (transfer)",
    other: "Inna",
    notarial: "Notarialna",
  },
  transaction_status: {
    draft: "Wersja robocza",
    in_acceptance: "W akceptacji",
    canceled: "Anulowana",
    accepted: "Zaakceptowana",
  },
  select_alert: {
    redirect: "Alert został pozyskany w wyniku przekierowania.",
    no_alert: "Żaden alert nie został jeszcze utworzony w systemie AML.",
    label: "Lista alertów",
  },
  task: {
    party: "podmiotu",
    person: "osoby",
  },
  risk_status: {
    pending: "W trakcie",
    low: "Niskie",
    normal: "Normalne",
    high: "Wysokie",
    non_acceptable: "Nieakceptowalne",
    error: "Błąd",
    cannot_be_processed: "Nie dotyczy",
    user_verification_required: "Wymagana weryfikacja przez użytkownika",
  },
  rules: {
    AverageTransactionsLimitRule: "Odchylenie od średniej",
    ClientTypeCheckRule: "Klient instytucjonalny",
    DefaultPartyStatusRule: "Domyślny status podmiotu",
    DefaultTransactionStatusRule: "Domyślny status transakcji",
    EconomicRelationsDurationRule: "Długość trwania stosunków gospodarczych",
    IndividualCustomerProfileRule: "Profil klienta indywidualnego",
    IndividualPartyAgeRule: "Wiek klienta indywidualnego",
    IndividualPartyGenderRule: "Płeć klienta indywidualnego",
    MaxmindPartyCheckRule: "Sprawdzenie podmiotu w Maxmind",
    MultipleTransactionsLimitRule: "Limit wielu transakcji",
    PartyCitizenshipFromCorruptCountryRule: "Obywatelstwo w grupie krajów z korupcją",
    PartyCitizenshipFromHighRiskCountryRule: "Obywatelstwo w krajach wysokiego ryzyka",
    PartyCitizenshipFromSanctionCountryRule: "Obywatelstwo w grupie krajów z sankcjami",
    PartyCitizenshipFromTaxHavenCountryRule: "Obywatelstwo w krajach z szkodliwą konkurencją podatkową",
    PartyCitizenshipFromTerrorismCountryRule: "Obywatelstwo w krajach finansujących oraz wspierających terroryzm",
    PepCheckRule: "Stanowisko eksponowane politycznie",
    PkdLimitRule: "Kody PKD wysokiego ryzyka",
    PkdPaymentInstitutionCheckRule: "Kod PKD instytucji płatniczej",
    SanctionListLimitRule: "Listy sankcyjne",
    SingleTransactionLimitRule: "Limit pojedynczej transakcji",
    TransactionRiskFromPartiesLimitRule: "Ryzyko stron transakcji",
    TransactionsCountryLimitRule: "Kraj zlecenia transakcji",
    TransactionSanctionListLimitRule: "Listy sankcyjne transakcji",
  },
  download_drive: "W celu pobrania pliku podłącz dysk",
  delete_drive: "W celu usunięcia pliku podłącz dysk",

  employment_types: {
    title: "Rodzaj zatrudnienia",
    student: "Student",
    retiree: "Emeryt",
    pensioner: "Rencista",
    entrepreneur: "Przedsiębiorca",
    employedUOP: "Zatrudniony na UOP",
    employedUZUOD: "Zatrudniony na UZ/UOD",
    unemployed: "Niezatrudniony",
    jobless: "Bezrobotny",
    annuitant: "Rentier",
  },
};
