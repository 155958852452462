export const commonTrans = {
  confirm: "Confirm",
  subscription_required: "Active subscription required",
  refresh: "Refresh",
  change: "Change",
  gender: "Gender",
  references: "References",
  first_name: "First name",
  postal_code: "Postal code",
  country: "Country",
  city: "City",
  street: "Street",
  house_number: "House number",
  flat_number: "Flat number",
  phone_number: "Phone number",
  ue: "European Union",
  ppv_service_name: "AML Quick Scan",
  login: "Log in",
  logout: "Log out",
  cancel: "Cancel",
  delete: "Delete",
  create: "Create",
  import: "Import",
  add: "Add",
  added: "Added",
  next: "Next",
  choose: "Choose",
  add_data: "Add data",
  data: "Data",
  edit: "Edit",
  edition_left: "Number of remaining editions: ",
  more: "More",
  save: "Save",
  save_confirmation: "Are you sure you want to save the changes?",
  set: "Set",
  close: "Close",
  continue: "Continue",
  details: "Details",
  return: "Return",
  create_another_one: "Create another one",
  male_create_new: "Create new",
  female_create_new: "Create new",
  create_another: "Create another",
  create_another_2: "Create another",
  search: "Search",
  download: "Download",
  download_pdf: "Download (PDF)",
  download_invoice_pdf: "Download invoice (PDF)",
  approve: "Approve",
  try_again: "Try again",
  industries: "Industries",
  warning: "Warning",
  yes: "Yes",
  no: "No",
  no_options: "No options",
  not_defined: "not defined",
  not_selected: "not selected",
  accept: "Accept",
  reject: "Reject",
  month: "month",
  months: "months",
  months_3: "months",
  quarter: "quarter",
  year: "year",
  code: "Code",
  description: "Description",
  date: "Date",
  attention: "Attention",
  summary: "Summary",
  bill: "Bill",
  confirmation: "Confirmation",
  advanced_search: "Advanced search",
  last_name: "Last name",
  company_name: "Company name",
  risk_assessment: "Risk assessment",
  rule_settings: "Rule settings",
  events: "Events",
  attachments: "Attachments",
  documents: "Documents",
  status_change: "Status change",
  accommodation_address: "Accommodation address",
  forward_address: "Forwarding address",
  business_address: "Business address",
  add_forward_address: "Add forwarding address",
  remove_forward_address: "Remove forwarding address",
  contact_data: "Contact data",
  company_contact: "Company contact",
  company_contact_add: "Add company contact",
  company_contact_remove: "Remove company contact",
  email_address: "Email address",
  phone_country: "Phone country",
  document_template: "Document template",
  order: "Order",
  rule: "Rule",
  page_not_found: "Page not found!",
  path_correct: "Make sure the path given is correct or",
  homepage: "homepage",
  go_to: "go to",
  page_found: "Page not found",
  aml_check: "AML check",
  main_panel: "Main panel",
  go_to_summary: "Go to summary",
  go_back_to_summary: "Go back to summary",
  products: "Products",
  cart: "Cart",

  document_types: {
    id_card: "Id card",
    electronic_id_card: "Electronic ID card",
    passport: "Passport",
    residency_card: "Residency card",
  },

  document_without_expiration_date: {
    true: "Yes",
    false: "No",
  },

  data_validation: {
    too_early: "Choose a date not earlier than {{$data}}",
    too_late: "Choose a date not later than {{$data}}",
    invalid: "Invalid date value",
    past_date: "A date from the past has been selected, check for correctness",
    future_date: "A date from the future has been selected, check for correctness",
  },

  report: {
    generation_info:
      "The process of creating the report has begun, it will be available for download from the documents tab.",
    generation_info_tools:"The report creation process has started, it will be available for download from the table below.",
    download_in_progress: "Download in progress",
    downloaded: "Downloaded",
    prepare_report: "Prepare report",
    create_giff_form: "Create an application form for the GIIF",
  },
  table: {
    code: "Code",
    description: "Description",
    party: "Party",
    date: "Date",
    status: "Status",
    name: "Name",
    booked_at: "Transaction order date",
    payment_date: "Payment date",
    created_at: "Created at",
    amount: "Amount",
    invoice: "Invoice",
    invoice_number: "Invoice number",
    transaction_entity: "Transaction entity",
    title: "Title",
    type: "Type",
  },
  industries_submenu: {
    museum: "AML for Antiquarians, Art Galleries and Auction Houses",
    bureau: "System AML for accounting offices",
    stock: "AML for exchange offices and virtual currency exchanges",
    payment: "AML system for small payment institutions and payment service bureaus",
  },
  systemAML: "System AML",
  required: "Required",
  party: "Party",
  parties: "Parties",
  transaction: "Transaction",
  transactions: "Transactions",
  beneficiaries: "Beneficiaries",
  board_members: "Representatives",
  verification: "Verification",
  cdd_list: "CDD - List",
  cdd: "CDD",
  customer_due_diligence: "Customer due diligence",
  verifications: "Verifications",
  applicant: "Applicant",
  pagination: {
    display: "Display",
    from: "of",
  },
  entity_type: {
    individual: "Person",
    individual_entity: "Person",
    beneficiary: "Person (beneficiary)",
    board_member: "Person (representative)",
    sole_proprietorship: "sole proprietorship",
    legal_entity: "Legal entity",
  },
  sanctioning_institutions: {
    ue: "EU",
    un: "UN",
    mswia: "MSWiA",
  },
  sanction_list_name: {
    sanctions_giif: "GIIF sanction list",
    sanctions_mswia: "MSWiA sanction list",
    eu_financial_sanctions: "EU sanction list (EU Financial Sanctions)",
    sanctions_un: "UN sanction list (United Nations Security Council)",
    sanctions_uk: "UK sanction list (HM Treasury)",
    sanctions_us_non_sdn: "US non-SDN sanction list (OFAC non Specially Designated Nationals)",
    sanctions_us_sdn: "US SDN sanction list (OFAC Specially Designated Nationals)",
  },
  select_party: {
    redirect: "The party was obtained through redirection.",
    no_party: "No party has been added in the system AML yet.",
    label: "Party list",
  },
  party_types: {
    individual: "Individual",
    sole_proprietorship: "Sole proprietorship",
    company: "Company",
    unknown: "Not defined",
    representative: "Representative",
  },
  party_status: {
    active: "Active",
    occasional: "Occasional",
    in_acceptance: "In acceptance",
    inactive: "Inactive",
    draft: "Draft",
    kyc_in_progress: "KYC in progress",
    rejected: "Rejected",
  },
  select_transaction: {
    redirect: "The transaction was obtained through redirection.",
    no_transaction: "No transaction has been added in the system AML yet.",
    label: "Transaction list",
  },
  transaction_types: {
    buyer: "Purchase",
    buyer_crypto: "Purchase of virtual currency",
    buyer_fiat: "Purchase of fiat currency",
    seller: "Sale",
    seller_crypto: "Sale of virtual currency",
    seller_fiat: "Sale of fiat currency",
    exchange_fiat: "Exchange of fiat currency",
    transfer: "Payment (transfer)",
    other: "Other",
    notarial: "Notary",
  },
  transaction_status: {
    draft: "Draft",
    in_acceptance: "In acceptance",
    canceled: "Canceled",
    accepted: "Accepted",
  },
  select_alert: {
    redirect: "The alert was obtained through redirection.",
    no_alert: "No alert has been added in the system AML yet.",
    label: "Alert list",
  },
  task: {
    party: "party",
    person: "person",
  },
  risk_status: {
    pending: "Pending",
    low: "Low",
    normal: "Normal",
    high: "High",
    non_acceptable: "Non acceptable",
    error: "Error",
    cannot_be_processed: "Cannot be processed",
    user_verification_required: "User verification required",
  },
  rules: {
    AverageTransactionsLimitRule: "Average transactions limit",
    ClientTypeCheckRule: "Institutional client",
    DefaultPartyStatusRule: "Default party status",
    DefaultTransactionStatusRule: "Default transaction status",
    EconomicRelationsDurationRule: "Economic relations duration check",
    IndividualCustomerProfileRule: "Individual customer profile",
    IndividualPartyAgeRule: "Individual party age",
    IndividualPartyGenderRule: "Individual party gender",
    MaxmindPartyCheckRule: "Maxmind party check",
    MultipleTransactionsLimitRule: "Multiple transactions limit",
    PartyCitizenshipFromCorruptCountryRule: "Party citizenship from corrupt country",
    PartyCitizenshipFromHighRiskCountryRule: "Party citizenship from high risk country",
    PartyCitizenshipFromSanctionCountryRule: "Party citizenship from sanction country",
    PartyCitizenshipFromTaxHavenCountryRule: "Party citizenship from tax haven country",
    PartyCitizenshipFromTerrorismCountryRule: "Party citizenship from terrorism country",
    PepCheckRule: "PEP check",
    PkdLimitRule: "PKD high risk list",
    PkdPaymentInstitutionCheckRule: "PKD payment institution",
    SanctionListLimitRule: "Sanction list",
    SingleTransactionLimitRule: "Single transaction limit",
    TransactionRiskFromPartiesLimitRule: "Transaction risk from parties",
    TransactionsCountryLimitRule: "Transactions country limit",
    TransactionSanctionListLimitRule: "Transactions Sanction list",
  },
  download_drive: "To download the file, connect the drive",
  delete_drive: "To delete a file, connect the drive",

  employment_types: {
    title: "Employment type",
    student: "Student",
    retiree: "Retiree",
    pensioner: "Pensioner",
    entrepreneur: "Entrepreneur",
    employedUOP: "Employed UOP",
    employedUZUOD: "Employed UZ/UOD",
    unemployed: "Unemployed",
    jobless: "Jobless",
    annuitant: "Annuitant",
  },
};
